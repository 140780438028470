@import "minima";

* {
  margin:0;
  padding:0;
}

body {
  font-family:'Noto Sans KR', sans-serif;
  color:#212b36;
  font-size:16px;
  text-size-adjust:none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure {
  margin:0;
}
h1, h2, h3, h4, h5, h6 {
  font-weight:bold;
}
blockquote {
  border:none;
  padding:0;
  color:inherit;
  font-size:inherit;
  font-style:normal;
}

.wrapper {
  max-width:-webkit-calc(980px - (30px * 2));
  max-width:calc(980px - (30px * 2));
}
.page-content {
  padding:0;
  font-size:16px;
  flex:auto;
}
.home-content {
  padding:0;
  font-size:16px;
  flex:auto;
}
@media (min-width:801px) {
  .wrapper {
    max-width:-webkit-calc(1280px - (30px * 2));
    max-width:calc(1280px - (30px * 2));
  }
  .page-content {
    width:920px;
    margin:0 auto;
  }
  .home-content {
    width:100%;
    margin:0 auto;
  }
}

/* Style Guides */
main > h2 {
  margin:25px 0;
  font-size:20px;
  letter-spacing:-2px;
  text-align:center;
  color:#000;
}
main > h3 {
  margin:25px 25px 15px;
  color:#313643;
  font-size:18px;
  letter-spacing:-1.4px;
}
@media (min-width:801px) {
  main > h2 {
    margin:40px 0;
    font-size:28px;
  }
  main > h3 {
    margin:40px 25px 30px;
    font-size:24px;
  } 
}
main > h3 strong {
  color:#f4512c;
}
main > h4,
main > h5,
main > h6 {
  margin:25px 25px 15px;
  color:#313643;
  font-size:16px;
  letter-spacing:-1.4px;
}
main > p {
  margin:15px 25px;
  line-height:150%;
  /*letter-spacing:-1.2px;*/
  text-align:justify;
  color:#212b36;
}
main > hr {
  margin:25px;
  border:none;
  border-bottom:1px dashed #9f9b9b;
}
main > iframe {
  width:100%;
  height:240px;
}
.call-to-action {
  margin:25px;
}
.call-to-action a,
.call-to-action button {
  display:block;
  width:100%;
  height:50px;
  border:2px solid #f4512c;
  background-color:#fff;
  box-sizing:border-box;
  color:#f4512c;
  font-size:16px;
  letter-spacing:-1.4px;
  text-decoration:none;
  text-align:center;
}
.call-to-action a {
  line-height:48px;
}
.call-to-action a + a,
.call-to-action a + button,
.call-to-action button + a,
.call-to-action button + button {
  margin-top:8px;
}
.call-to-action strong {
  zoom:1;
  display:inline-block;
  position:relative;
}
.call-to-action strong:before {
  content:"";
  display:block;
  position:absolute;
  top:50%;
  left:100%;
  width:35px;
  height:2px;
  margin-top:-1px;
  margin-left:8px;
  background-color:#f4512c;
}
.call-to-action strong:after {
  content:"";
  display:block;
  position:absolute;
  top:50%;
  left:100%;
  width:10px;
  height:2px;
  margin-top:-1px;
  margin-left:32px;
  background-color:#f4512c;
  transform:rotate(30deg);
  transform-origin:100% 100%;
}
.call-to-action img {
  margin-left:5px;
  vertical-align:-9px;
}
.content-heading {
  padding:25px 0 15px;
  color:#555;
  font-size:18px;
  font-weight:normal;
  letter-spacing:-1.6px;
  text-align:center;
}
.content-heading small {
  display:block;
  font-size:14px;
  letter-spacing:-1.2px;
  color:#fc9a0d;
}
.content-heading strong  {
  display:block;
  font-size:20px;
  letter-spacing:-2px;
  color:#000;
}
.content-intro {
  position:relative;
  margin:25px;
  padding:15px 0;
  line-height:150%;
  letter-spacing:-1.2px;
  text-align:justify;
}
.content-intro:before {
  content:"“";
  position:absolute;
  top:0;
  left:0;
  font-size:24px;
  font-weight:bold;
  color:#f4512c;
}
.content-intro:after {
  content:"”";
  position:absolute;
  right:0;
  bottom:0;
  font-size:24px;
  font-weight:bold;
  color:#f4512c;
}
.content-intro figure {
  margin:30px 0;
  text-align:center;
}
.content-intro figure img {
  display:inline;
  margin:0 12px;
}
.content-intro p {
  margin:25px 0;
}
.content-intro p:first-child {
  margin-top:0;
}
.content-intro p:last-child {
  margin-bottom:0;
}
.content-sculptpost {
  margin:25px;
  padding:15px 10px;
  border:1px solid #d7d7d7;
}
.content-sculptpost p {
  margin:15px 0;
}
.content-sculptpost p:first-child {
  margin-top:0;
}
.content-sculptpost p:last-child {
  margin-bottom:0;
}
.content-sculptpost blockquote {
  padding-left:10px;
  border-left:2px solid #f4512c;
  font-weight:bold;
  letter-spacing:-1.2px;
}
.content-sculptpost blockquote strong {
  color:#f4512c;
}
.content-history {
  padding:25px 25px 125px;
  background:#f9fafb url(../img-watermark-half.svg) 100% 100% no-repeat;
}
.content-history h3 {
  font-size:18px;
  letter-spacing: -1.4px;
  color:#000;
}
.content-history ul {
  margin-top:15px;
}
.content-history ul li {
  position:relative;
  padding-left:8px;
  letter-spacing:-1.2px;
  list-style:none;
}
.content-history ul li:before {
  content:"·";
  display:block;
  position:absolute;
  top:0;
  left:0;
}
.content-history ul li time {
  zoom:1;
  display:inline-block;
  width:50px;
  font-weight:bold;
}
.content-important {
  margin:25px;
  padding:20px 8px;
  border:1px solid #f4512c;
  color:#f4512c;
  font-size:16px;
  letter-spacing:-1.4px;
  text-align:center;
}
.content-important mark {
  background:none;
  color:#f4512c;
  font-weight:bold;
}
.content-caution {
  padding:20px;
  background-color:#f4512c;
  background-image:linear-gradient(#f4512c, #f4512c 30%, #ffb800);
  color:#fff;
  text-align:center;
}
.content-caution h3 {
  font-size:18px;
  letter-spacing:-1.4px;
  color:#fff;
}
.content-caution h3 img {
  display:block;
  margin:0 auto 10px;
}
.content-caution p {
  margin-top:15px;
  font-size:16px;
  line-height:150%;
  letter-spacing:-1.2px;
  color:#fff;
}
.content-iconcard {
  margin:25px;
  padding:12px 20px;
  border-radius:15px;
  border:1px solid #c1c2c6;
  text-align:center;
}
.content-iconcard + .content-iconcard {
  margin-top:-18px;
}
.content-iconcard h3 {
  font-size:16px;
  line-height:150%;
  letter-spacing:-1.2px;
  color:#f4512c;
}
.content-iconcard h3 img {
  display:block;
  margin:0 auto 5px;
}
.content-iconcard p {
  margin-top:8px;
  font-size:14px;
  line-height:150%;
  letter-spacing:-1.2px;
  color:#212b36;
}
.content-casecard {
  position:relative;
  margin:4px 0;
  font-size:0;
  background-color:#f9fafb;
  cursor:pointer;
}
.content-casecard:after {
  display:block;
  content:"";
  position:absolute;
  top:50%;
  right:25px;
  width:18px;
  height:18px;
  margin-top:-9px;
  background:url(../icon-toggle-down.svg) no-repeat 0 0;
}
.content-casecard figure {
  zoom:1;
  display:inline-block;
  width:40%;
  margin-right:5%;
  vertical-align:middle;
}
.content-casecard figure img {
  display:block;
  width:100%;
}
.content-casecard p {
  zoom:1;
  display:inline-block;
  width:40%;
  color:#212b36;
  font-size:12px;
  line-height:125%;
  letter-spacing:-1.5px;
  vertical-align:middle;
}
.content-casecard p mark {
  display:block;
  margin-top:4px;
  background:none;
  color:#000;
  font-size:16px;
  font-weight:bold;
  letter-spacing:-1.4px;
}
.content-definition {
  margin-top:40px;
  padding:40px 25px 30px;
  border-radius:30px 0 0 0;
  background-color:#f4512c;
  color:#fff;
}
.content-definition h3 {
  font-size:20px;
  font-weight:300;
  letter-spacing:-2px;
}
.content-definition p {
  margin-top:10px;
  font-size:16px;
  line-height:150%;
  letter-spacing:-1.2px;
  text-align:justify;
}

/* Custom Components */
.tab {
  overflow:hidden;
}
.tab a,
.tab strong {
  overflow:hidden;
  float:left;
  min-width:50%;
  height:40px;
  line-height:40px;
  background-color:#b5b5b5;
  font-size:16px;
  letter-spacing:-1.4px;
  color:#fff;
  font-weight:bold;
  text-decoration:none;
  text-align:center;
}
.tab a {
  color:#ddd;
}
.tab strong {
  background-color:#fc9a0d;
}
.call-to-action {
  margin:25px;
}
.call-to-action a,
.call-to-action button {
  display:block;
  width:100%;
  height:50px;
  border:2px solid #f4512c;
  background-color:#fff;
  box-sizing:border-box;
  color:#f4512c;
  font-size:16px;
  letter-spacing:-1.4px;
  text-indent:-10px;
  text-decoration:none;
  text-align:center;
}
.call-to-action a {
  line-height:48px;
}
.call-to-action a + a,
.call-to-action a + button,
.call-to-action button + a,
.call-to-action button + button {
  margin-top:8px;
}
.call-to-action strong {
  zoom:1;
  display:inline-block;
  position:relative;
}
.call-to-action strong:before {
  content:"";
  display:block;
  position:absolute;
  top:50%;
  left:100%;
  width:35px;
  height:2px;
  margin-top:-1px;
  margin-left:8px;
  background-color:#f4512c;
}
.call-to-action strong:after {
  content:"";
  display:block;
  position:absolute;
  top:50%;
  left:100%;
  width:10px;
  height:2px;
  margin-top:-1px;
  margin-left:32px;
  background-color:#f4512c;
  transform:rotate(30deg);
  transform-origin:100% 100%;
}
.call-to-action img {
  margin-left:5px;
  vertical-align:-9px;
}
.featured-articles {
  overflow:auto;
  width:auto;
  margin:15px 0;
  padding:0 25px;
  font-size:0;
  text-align:center;
  white-space:nowrap;
  -webkit-overflow-scrolling:touch;
}
.featured-articles dl {
  zoom:1;
  display:inline-block;
  width:150px;
  margin-right:15px;
  text-align:left;
  vertical-align:top;
  cursor:pointer;
}
.featured-articles dl dt img {
  width:150px;
  height:90px;
  border-radius:5px;
  object-fit:cover;
}
.featured-articles dl dd {
  margin-top:10px;
}
.featured-articles dl dd strong {
  display:block;
  font-size:16px;
  font-weight:500;
  letter-spacing:-1px;
  color:#313643;
}
.featured-articles dl dd p {
  margin-top:2px;
  font-size:14px;
  letter-spacing:-0.8px;
  color:#666;
  white-space:normal;
}
@media (min-width:801px) {
  .featured-articles {
    overflow:hidden;
    max-width:1280px;
    margin:40px auto;
    padding:0 25px;
    text-align:center;
    white-space:normal;
  }
  .featured-articles dl {
    width:23%;
    margin:0 1%;
  }
  .featured-articles dl dt {
    position:relative;
    width:100%;
    height:0;
    padding-bottom:56.25%;
  }
  .featured-articles dl dt img {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  .featured-articles dl dd {
    margin-top:20px;
  }
  .featured-articles dl dd strong {
    font-size:20px;
  }
  .featured-articles dl dd p {
    margin-top:5px;
    font-size:17px;
  }
}
